<template>
    <b-overlay>
        <b-card>
            <b-col>
                <b-row>
                    <b-form-group label="Privzeta vrednost za m² na plan">
                        <b-form-input v-model="config.daily_cut_capacity" type="number"/>
                    </b-form-group>
                </b-row>
                <b-row>
                    <b-button variant="success" @click="saveConfig()">Shrani</b-button>
                </b-row>
                <b-row>
                    <b-button variant="danger" class="mt-2" @click="refreshBC()">Osveži podatke iz BC</b-button>
                </b-row>
            </b-col>
        </b-card>
    </b-overlay>
</template>

<script>

    export default {
        data() {
            return {
                config: {daily_cut_capacity: 500}

            }
        },
        methods: {
            getConfig() {
                const thisIns = this
                this.$http.get('/api/v1/user/common_config/')
                    .then(function(response) {
                        thisIns.config = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            saveConfig() {
                const thisIns = this
                this.$http.patch('/api/v1/user/common_config/', {daily_cut_capacity: Number(this.config.daily_cut_capacity)})
                    .then(function() {
                        thisIns.$printSuccess('Nastavitve posodobljene')
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            refreshBC() {
                const thisIns = this
                this.$http.post('/api/v1/user/refresh_redis/')
                    .then(function() {
                        thisIns.$printSuccess('Podatki osveženi')
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            }
        },
        created() {
            this.getConfig()

        }
    }
</script>

<style>

</style>
